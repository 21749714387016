<!--
 * @Author: gulingfeng
 * @Date: 2021-04-20 13:22:50
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-09-24 09:47:45
 * @Description: 新增客户信息
-->
<style lang="less" scoped>
.ant-drawer-body {
  .ant-form-item {
    display: flex;
    flex-wrap: nowrap;
    /deep/.ant-form-item-control-wrapper {
      height: auto;
      line-height: normal;
    }
    .email {
      margin-left: 10px;
      width: 95%;
    }
  }
}

/deep/.ant-drawer-title {
  font-weight: bold !important;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
</style>

<template>
  <a-drawer
    width="526px"
    :visible="visible"
    @close="handleClose"
    :title="id != '' ? $t('customer.update_company') : $t('customer.save_company')"
  >
    <NeoPageLayout>
      <a-form-model
        :colon="false"
        ref="customerForm"
        :rules="customerFormRules"
        v-bind="layout.form"
        :model="customerFormData"
      >
        <div class="addStepOne">
          <a-row>
            <a-col :span="24">
              <a-form-model-item :label="$t('newCompany.cus_tax')" prop="taxIdNumber">
                <a-input
                  v-model="customerFormData.taxIdNumber"
                  :placeholder="$t('newCompany.cus_tax_holder')"
                  :maxLength="20"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item :label="$t('newCompany.cus_entityname')" prop="entityName">
                <a-input
                  v-model="customerFormData.entityName"
                  :placeholder="$t('newCompany.cus_entityname_holder')"
                  :maxLength="100"
                  @blur="syncClient"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item :label="$t('newCompany.cus_address')" prop="address">
                <a-input
                  v-model="customerFormData.address"
                  :placeholder="$t('newCompany.cus_address_holder')"
                  :maxLength="100"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item :label="$t('newCompany.cus_currency')" prop="currency">
                <a-select :placeholder="$t('customer.currency')" v-model="customerFormData.currency">
                  <a-select-option v-for="(item, index) of currency" :key="index" :value="item.dictValue">{{
                    item.dictLabel.split(';')[0]
                  }}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item :label="$t('newCompany.cus_admin')" prop="employeeName">
                <a-input
                  class="admin"
                  v-model="customerFormData.employeeName"
                  :placeholder="$t('newCompany.cus_admin_name_holder')"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <a-form-model-item prop="email">
                <a-input
                  class="admin email"
                  v-model="customerFormData.email"
                  :placeholder="$t('newCompany.cus_admin_email_holder')"
                ></a-input>
              </a-form-model-item>
            </a-col>

            <a-col :span="24">
              <a-form-model-item label="Client" prop="comMapping" ref="comMapping">
                <a-input
                  class="admin"
                  ref="comMapping"
                  v-model="customerFormData.comMapping"
                  :placeholder="$t('newCompany.cus_comMapping_name')"
                ></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
      </a-form-model>
    </NeoPageLayout>
    <div class="footer">
      <a-button type="primary" :loading="loading" @click="submit"> {{ $t('submit') }} </a-button>
    </div>
  </a-drawer>
</template>

<script>
const { validate } = window.$g
export default {
  name: 'AddCustomerInfo',
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      comMappingShow: true,
      customerFormData: {
        taxIdNumber: '',
        entityName: '',
        address: '',
        currency: undefined,
        employeeName: '',
        email: '',
        comMapping: '',
      },
      layout: {
        form: {
          labelCol: { flex: '100px' },
          wrapperCol: { flex: 'auto' },
        },
        item: {
          labelCol: { flex: '100px' },
          wrapperCol: { flex: 'auto' },
        },
        item_long: {
          labelCol: { flex: '150px' },
          wrapperCol: { flex: 'auto' },
        },
      },
      customerFormRules: {
        taxIdNumber: [
          validate.required,
          validate.taxIdNumber,
          { required: true, validator: this.validatorCheckByTaxIdNumber, trigger: 'blur' },
        ],
        entityName: [validate.required, { required: true, validator: this.validatorCheckByCusName, trigger: 'blur' }],
        address: [validate.required],
        currency: [validate.required],
        comMapping: [validate.required],
        employeeName: [validate.required, validate.size_10],
        email: [
          validate.email,
          validate.no_gt_email,
          validate.required,
          { required: true, validator: this.validatorCheckByEmail, trigger: 'blur' },
        ],
      },
      customerInfo: {},
    }
  },
  created() {},
  mounted() {},
  computed: {
    currency() {
      return this.$store.getters['app/getDictByType']('CURRENCY')
    },
  },
  watch: {
    visible(val) {
      if (val) {
        console.log('id:' + this.id)
        if (this.$g.noEmpty(this.id)) {
          this.get(this.id)
        }
      } else {
        this.$g.resetData(this)
      }
    },
  },
  methods: {
    syncClient() {
      const v = this.customerFormData.entityName
      console.log('v :>> ', v)
      if (this.$g.noEmpty(v) && this.$g.isEmpty(this.customerFormData.comMapping)) {
        this.customerFormData.comMapping = v
        this.$refs.comMapping.onFieldChange()
        console.log('this.$refs.comMapping :>> ', this.$refs.comMapping)
      }
    },
    // 校验实体名是否存在
    async validatorCheckByCusName(rule_, value, callback) {
      if (this.customerInfo.name && this.customerInfo.name === this.customerFormData.entityName) {
        callback()
      }
      try {
        const data = await this.$http('checkByCusName', { cusName: this.customerFormData.entityName })
        if (data) {
          callback(this.$t('newCompany.cus_entityname_existed'))
        } else {
          callback()
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 校验税号是否存在
    async validatorCheckByTaxIdNumber(rule_, value, callback) {
      if (this.customerInfo.taxIdNumber && this.customerInfo.taxIdNumber === this.customerFormData.taxIdNumber) {
        callback()
      }
      try {
        const data = await this.$http('checkByTaxIdNumber', { taxIdNumber: this.customerFormData.taxIdNumber })
        if (data) {
          callback(this.$t('newCompany.cus_tax_existed'))
        } else {
          callback()
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    // 校验邮箱是否存在
    async validatorCheckByEmail(rule_, value, callback) {
      if (this.customerInfo.email && this.customerInfo.email === this.customerFormData.email) {
        callback()
      }
      try {
        const data = await this.$http('checkByEmail', { email: this.customerFormData.email, cusEntityId: this.id })
        if (data) {
          callback(this.$t('newCompany.cus_admin_email_existed'))
        } else {
          callback()
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    submit() {
      this.$refs.customerForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          try {
            const data = await this.$http(
              this.customerFormData.id ? 'cusInfoUpdateCusInfo' : 'cusInfoSave',
              this.customerFormData
            )
            if (data) {
              setTimeout(() => {
                this.customerFormData = {
                  taxIdNumber: '',
                  entityName: '',
                  address: '',
                  currency: undefined,
                  employeeName: '',
                  email: '',
                  comMapping: '',
                }
                this.loading = false
                this.customerInfo = {}
                this.$emit('update:visible', false)
                this.$emit('submitCallback')
              }, 1000)
            }
          } catch (err) {
            this.loading = false
            this.$httpNotify(err)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 客户实体表-客户详情
    async get(cid) {
      try {
        const data = await this.$http({
          key: 'getCusInfo',
          params: { id: cid },
        })
        this.customerInfo = data
        const { id, taxIdNumber, name, address, currency, topManager, topManagerName, email, comMapping } = data
        this.customerFormData = {
          id,
          taxIdNumber,
          entityName: name,
          address,
          currency,
          topManager,
          employeeName: topManagerName,
          email,
          comMapping,
        }
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    handleClose() {
      this.$refs.customerForm.resetFields()
      this.customerFormData = {
        taxIdNumber: '',
        entityName: '',
        address: '',
        currency: undefined,
        employeeName: '',
        email: '',
      }
      this.customerInfo = {}
      this.$emit('update:visible', false)
    },
  },
}
</script>
